import { proxy } from 'valtio';

import {
  createSilentAudioElement,
  unlockAudioContext,
} from '../../../services/audio/audio-context';
import { createProvider } from '../../../utils/createProvider';
import { HTMLAudioPool, WebAudioPool } from '../../../utils/unplayable';
import { markSnapshottable } from '../../../utils/valtio';

type State = {
  audioReady: boolean;
};

class AudioDetectionAPI {
  private _state = markSnapshottable(
    proxy<State>({
      audioReady: false,
    })
  );
  private aborter = new AbortController();
  private audioContextReady = false;
  private audioElementReady = false;

  constructor() {
    this.ensureUnlocked();
  }

  get state() {
    return this._state;
  }

  async tryUnlock(): Promise<boolean> {
    if (this._state.audioReady) return true;
    try {
      await this.unlockAudioElement();
      await this.unlockAudioContext();
      HTMLAudioPool.Make();
      WebAudioPool.Make();
    } finally {
      this.computeAudioReady();
    }
    return this._state.audioReady;
  }

  private async unlockAudioElement(): Promise<boolean> {
    const audio = createSilentAudioElement();
    try {
      await audio.play();
      this.audioElementReady = true;
    } catch (e) {
      this.audioElementReady = false;
    }
    return this.audioElementReady;
  }

  private async unlockAudioContext(): Promise<boolean> {
    this.audioContextReady = await unlockAudioContext();
    return this.audioContextReady;
  }

  private ensureUnlocked() {
    const opts = {
      once: true,
      signal: this.aborter.signal,
    };

    const handler = async () => {
      if (!(await this.tryUnlock())) {
        document.body.addEventListener('touchend', handler, opts);
        document.body.addEventListener('pointerdown', handler, opts);
      }
    };

    document.body.addEventListener('touchend', handler, opts);
    document.body.addEventListener('pointerdown', handler, opts);
  }

  private computeAudioReady() {
    this._state.audioReady = this.audioContextReady && this.audioElementReady;
  }
}

const globalAudioDetectionAPI = new AudioDetectionAPI();

const { Provider, useCreatedContext } =
  createProvider<AudioDetectionAPI>('AudioDetectionAPI');

export { useCreatedContext as useGlobalAudioDetectionAPI };
export function GlobalAudioDetectionProvider(props: {
  children?: React.ReactNode;
}) {
  return <Provider value={globalAudioDetectionAPI}>{props.children}</Provider>;
}
